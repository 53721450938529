<template>
  <v-dialog
    v-model="dialog"
    max-width="600"
    scrollable
  >
    <v-card>
      <v-toolbar dark>
        <v-card-title>
          <span>{{ title }} exam option</span>
          <hr>
        </v-card-title>
        <v-spacer></v-spacer>
        <v-toolbar-items>
          <v-spacer></v-spacer>
          <v-btn icon dark @click="closeDialog">
            <i class="fa fa-close"></i>
          </v-btn>
        </v-toolbar-items>
      </v-toolbar>

      <v-card-text>
        <v-container>
          <v-row class="mt-2">
            <v-col cols="12" md="12">
              <v-text-field
                outlined
                :error="$v.exam_option.name.$error"
                dense
                v-model="exam_option.name"
              >
                <template v-slot:label>
                  Exam option title <span class="text-danger">*</span>
                </template>
              </v-text-field>
              <span class="text-danger" v-if="errors.name" >{{errors.name[0]}}</span>
              <span class="text-danger" v-if="$v.exam_option.name.$error">This information is required</span>
            </v-col>

            <v-col cols="12" md="12">
                <div class="text-body">
                  Description <span class="text-danger">*</span>
                </div>
                <ckeditor
                  :error="$v.exam_option.description.$error"
                  :config="editorConfig"
                  v-model="exam_option.description" >
                </ckeditor>
                <span class="text-danger" v-if="errors.description" >{{errors.description[0]}}</span>
                <span class="text-danger" v-if="$v.exam_option.description.$error">This information is required</span>
            </v-col>

            <v-col cols="12" sm="12">
              <div class="text-body">Grades</div>
              <v-select
                  item-text="name"
                  item-value="id"
                  v-model="exam_option.applicable_grades"
                  :items="grades"
                  multiple
                  small-chips
                  deletable-chips
                  clearable
                  outlined
                  dense :error="$v.exam_option.applicable_grades.$error"
              >
                <template v-slot:prepend-item>
                  <v-list-item
                      ripple
                      @click="allSelectGrades"
                  >
                    <v-list-item-action>
                      <v-icon :color="exam_option.applicable_grades > 0 ? 'indigo darken-4' : ''">
                        {{ iconGrade }}
                      </v-icon>
                    </v-list-item-action>
                    <v-list-item-content>
                      <v-list-item-title>
                        Select all
                      </v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
                  <v-divider class="mt-1"></v-divider>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.exam_option.applicable_grades.$error">This information is required</span>
              <span class="text-danger" v-if="errors.applicable_grades">{{ errors.applicable_grades[0] }}</span>
            </v-col>
            <v-col cols="12" sm="12" md="6">
              <v-select
                v-model="exam_option.category_id"
                :items="categories"
                outlined
                :error="$v.exam_option.category_id.$error"
                @change="changeCategory"
                item-text="name"
                item-value="id"
                dense
              >
                <template v-slot:label>
                  Category <span class="text-danger">*</span>
                </template>
              </v-select>
              <span class="text-danger" v-if="$v.exam_option.category_id.$error">This information is required</span>
              <span class="text-danger" v-if="errors.category_id">{{ errors.category_id[0] }}</span>
            </v-col>
            <v-col v-if="instrument_flag" cols="12" sm="12" md="6">
              <v-select
                  label="Family"
                  v-model="exam_option.instrument_id"
                  :items="instruments"
                  outlined
                  :loading="loading_instrument"
                  @change="changeInstrument"
                  item-text="name"
                  item-value="id"
                  dense
              >
              </v-select>
              <span class="text-danger" v-if="errors.instrument_id">{{ errors.instrument_id[0] }}</span>
            </v-col>
            <v-col v-if="syllabus_flag" cols="12" sm="12" md="6">
              <v-autocomplete
                  label="Syllabus"
                  v-model="exam_option.syllabus_id"
                  :items="syllabuses"
                  outlined
                  @change="changeSyllabus"
                  item-text="name"
                  :loading="loading_syllabus"
                  item-value="id"
                  dense
              >
              </v-autocomplete>
              <span class="text-danger" v-if="errors.syllabus_id">{{ errors.syllabus_id[0] }}</span>
            </v-col>
            <v-col cols="12" sm="12" md="6" v-if="exam_type_flag">
              <v-select
                  label="Exam type"
                  v-model="exam_option.exam_type"
                  :items="exam_types"
                  outlined
                  item-text="name"
                  item-value="id"
                  dense
              >
              </v-select>
              <span class="text-danger" v-if="errors.exam_type">{{ errors.exam_type[0] }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12" sm="12" md="3">
              Status <span class="text-danger">*</span>
              <v-switch
                  v-model="exam_option.is_active"
                  :label="exam_option.is_active?'Active':'Inactive'"
                  color="primary"
              ></v-switch>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
      <v-card-actions>
        <v-spacer></v-spacer>

        <v-btn
           x-large
           text
            @click="closeDialog">
          Cancel
        </v-btn>
        <v-btn
            dark
            x-large
            @click="createOrUpdate()"
            :loading="loading"
        >
          Save
        </v-btn>

      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import ExamOptionService from "@/services/product/exam-option/ExamOptionService";
import ExamTypeService from "@/services/product/exam-type/ExamTypeService";
import CategoryService from "@/services/product/category/CategoryService";
import {required} from "vuelidate/lib/validators";
import GradeService from "@/services/product/grade/GradeService";
import InstrumentService from "@/services/product/instrument/InstrumentService";
import SyllabusService from "@/services/product/syllabus/SyllabusService";

const exam_option = new ExamOptionService();
const exam_type = new ExamTypeService();
const grade = new GradeService();
const category = new CategoryService();
const instrument = new InstrumentService();
const syllabus = new SyllabusService();

export default {
  validations: {
    exam_option: {
      name:{required},
      description: {required},
      applicable_grades: {required},
      category_id: {required},
      is_active: {required}
    }
  },
  data() {
    return {
        title: "",
        edit: false,
        errors: [],
        dialog: false,
        loading: false,
        loading_instrument: false,
        loading_syllabus: false,
        grades: [],
        categories: [],
        instruments: [],
        syllabuses: [],
        exam_types: [],
        exam_option: {
            name: '',
            description: '',
            applicable_grades: [],
            category_id: null,
            instrument_id: null,
            syllabus_id: null,
            exam_type: null,
            position: null,
            is_active: 1
        },
        editorConfig: {
            toolbar: [ [ 'Bold', 'Italic', 'Underline', 'Strike', 'RemoveFormat', 'NumberedList', 'BulletedList' ] ],
            versionCheck: false
        },
        instrument_flag: false,
        syllabus_flag: false,
        exam_type_flag: false,
        categoryDetail:null,
        instrumentDetail:null,
        syllabusDetail:null,
        examTypeDetail:null,
    }
  },
  methods: {
    closeDialog() {
      this.dialog = false;
      this.errors = [];
      this.resetForm();
    },
    openDialog() {
      this.$v.$reset();
      this.dialog = true;
    },
    createExamOption() {
      this.resetForm();
      this.openDialog();
      this.edit = false;
      this.title = "Add";
    },
    editExamOption(item) {

      this.openDialog();
      this.edit = true;
      this.exam_option = item;
      this.title = "Edit";
      if(item.applicable_grades == null) {
        this.exam_option.applicable_grades = []
      }
     this.changeCategory();
    },
    createOrUpdate() {
      this.$v.$touch()
      if (this.$v.$error) {
        setTimeout(() => {
          this.$v.$reset()
        }, 3000);
      }
      else {
        if (!this.edit) this._create();
        else this.update();
      }
    },
    allSelectGrades() {
      this.$nextTick(() => {
        if (this.allSelectGrade) {
          this.exam_option.applicable_grades = []
        } else {
          this.exam_option.applicable_grades = [];
          this.grades.forEach(grade => {
            this.exam_option.applicable_grades.push(grade.id);
          });
        }
      })
    },
    getAllGrades() {
        grade
        .all()
        .then(response => {
        this.grades = response.data.grades;
        })
        .catch((err) => {
        });
    },


    _create: function () {
      this.loading = true;
      exam_option
      .create(this.exam_option)
      .then(response => {
        this.$snotify.success("Exam option added");
        this.resetForm();
        this.closeDialog();
        this.loading = false;
        this.$emit('refresh');
      })
      .catch((err) => {
        this.loading = false;
        this.errors = err.response.data.errors;
        this.$snotify.error("Oops something went wrong");
      });
    },
    update: function () {
      this.loading = true;
      exam_option
      .update(this.exam_option.id, this.exam_option)
      .then(response => {
        this.$snotify.success("Exam option updated");
        this.resetForm();
        this.closeDialog();
        this.loading = false;
        this.$emit('refresh');
      })
      .catch((err) => {
        this.loading = false;
        this.errors = err.response.data.errors;
        this.$snotify.error("Oops something went wrong");
      });
    },
    resetForm() {
      this.exam_option = {
        name: '',
        description: '',
        applicable_grades: [],
        category_id: null,
        instrument_id: null,
        syllabus_id: null,
        exam_type: null,
        position: null,
        is_active: 1
    },
          this.instrument_flag=false;
          this.syllabus_flag= false;
          this.exam_type_flag= false;
      this.$v.$reset();
      this.errors=[];
    },
    //Category
    getAllCategories() {
      let data={};
      data={'has_exam_options':1}
      category
          .getFilterData(data)
          .then(response => {
            this.categories = response.data.categories;
          })
          .catch((err) => {
          });
    },
    changeCategory(){
      this.getCategoryDetail();
    },
    getCategoryDetail(){
      category
          .show(this.exam_option.category_id)
          .then(response => {
            this.categoryDetail=response.data.category;
            if(this.categoryDetail && this.categoryDetail.has_instruments){
              this.instrument_flag=true;
              this.getInstruments();
              if(this.exam_option.instrument_id){
                this.getInstrumentDetail();
              }
            }else{
              this.instrument_flag=false;
            }
            if(this.categoryDetail && !this.categoryDetail.has_instruments && this.categoryDetail.has_syllabuses){
              this.syllabus_flag=true;
              this.getSyllabuses();
              if(this.exam_option.syllabus_id){
                this.getSyllabusDetail();
              }
            }else{
              this.syllabus_flag=false
            }
            if(this.categoryDetail && !this.categoryDetail.has_instruments && !this.categoryDetail.has_syllabuses && this.categoryDetail.has_exam_types){
              this.exam_type_flag=true;
              this.getAllExamTypes();
            }else{
              this.exam_type_flag=false
            }
          })
          .catch((err) => {
            this.$snotify.error("Oops something went wrong");
          });
    },
    //Instrument
    getInstruments() {
      let data={};
      if(this.exam_option.category_id){
        data['category_id']=this.exam_option.category_id;
      }
      data['has_exam_options']=1;
      instrument
          .getFilterData(data)
          .then(response => {
            this.instruments=response.data.instruments
          })
          .catch((err) => {
          });
    },
    changeInstrument() {
      this.getInstrumentDetail();
    },
    getInstrumentDetail(){
      if(this.exam_option.instrument_id){
        instrument
            .show(this.exam_option.instrument_id)
            .then(response => {
              this.instrumentDetail=response.data.instrument;
              if(this.instrumentDetail && this.instrumentDetail.has_syllabuses && this.categoryDetail && this.categoryDetail.has_syllabuses){
                this.syllabus_flag=true;
                this.getSyllabuses();
                if(this.exam_option.syllabus_id){
                  this.getSyllabusDetail();
                }
              }else{
                this.syllabus_flag=false;
              }
              if(this.instrumentDetail && !this.instrumentDetail.has_syllabuses && this.instrumentDetail.has_exam_types){
                this.exam_type_flag=true;
                this.getAllExamTypes();
              }else{
                this.exam_type_flag=false;
              }
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
      }

    },
    //Syllabus
    getSyllabuses(){
      let data={};
      if(this.exam_option.category_id){
        data['category_id']=this.exam_option.category_id;
      }
      if(this.exam_option.instrument_id){
        data['instrument_id']=this.exam_option.instrument_id;
      }
      data['has_exam_options']=1;
      syllabus
          .getFilterData(data)
          .then(response => {
            this.syllabuses=response.data.syllabuses;
          })
          .catch((err) => {

          });
    },
    changeSyllabus(){
        this.getSyllabusDetail();
    },
    getSyllabusDetail(){
      if(this.exam_option.syllabus_id){
        syllabus
            .show(this.exam_option.syllabus_id)
            .then(response => {
              this.syllabusDetail=response.data.syllabus;
              if(this.syllabusDetail && this.syllabusDetail.has_exam_types && this.categoryDetail && this.categoryDetail.has_exam_types){
                this.exam_type_flag=true;
                this.getAllExamTypes();
              }else{
                this.exam_type_flag=false;
              }
            })
            .catch((err) => {
              this.$snotify.error("Oops something went wrong");
            });
      }

    },
    //Exam Type
    getAllExamTypes() {
      let data={};
      if(this.exam_option.category_id){
        data['category_id']=this.exam_option.category_id;
      }
      if(this.exam_option.instrument_id){
        data['instrument_id']=this.exam_option.instrument_id;
      }
      if(this.exam_option.syllabus_id){
        data['syllabus_id']=this.exam_option.syllabus_id;
      }
      data['has_exam_options']=1;
      exam_type
          .getFilterData(data)
          .then(response => {
            this.exam_types = response.data.exam_types;
          })
          .catch((err) => {
          });
    },




  },
  mounted(){
    this.getAllGrades();
    this.getAllCategories();

  },
  computed: {
    allSelectGrade () {
      return this.exam_option.applicable_grades.length === this.grades.length
    },
    selectGrade () {
      return this.exam_option.applicable_grades.length > 0 && !this.allSelectGrade
    },
    iconGrade () {
      if (this.allSelectGrade) return 'mdi-close-box'
      if (this.selectGrade) return 'mdi-minus-box'
      return 'mdi-checkbox-blank-outline'
    }
  }
}
</script>
